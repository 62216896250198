import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import { Text, Tooltip, Accordion } from '../../designSystem';
import Icon from '../../designSystem/Icon';

import styles from './CoverageTable.module.scss';

import { formatUF } from '../../utils/format';
import { coverageTableTexts } from '../../utils/generalTexts';
import { detailsCategoriesPropTypes } from '../../types/availablePlansPropTypes';
import {
  DetailsCategories as DetailsCategoriesType,
  CategoryItems as CategoryItemsType,
} from '../../types/availablePlans';

function CoverageDetail({ category }: InferProps<typeof CoverageDetail.propTypes>) {
  const [showTooltip, setShowTooltip] = useState(false);

  const coverageContainerClass = classnames(
    styles.coverage_info_container,
    styles.coverage_maximum_title_container
  );
  const bonusClasses = classnames(styles.coverage_text, styles.coverage_bonus_text);
  const bonusValueClasses = classnames(styles.coverage_text, styles.coverage_text_bold);

  const onOpenTooltipHandler = () => setShowTooltip(true);
  const onCloseTooltipHandler = () => setShowTooltip(false);

  const maximumValue = (item: any) =>
    `${
      item.maximum.includeValue && !!item.maximum.value
        ? `<b>${formatUF(item.maximum.value.uf, {
            fixedDecimals: false,
          })}</b> <br/>`
        : ''
    } ${item.maximum.description}`;

  return (
    <div className={styles.coverage_container}>
      <div className={styles.coverage_table}>
        <div className={styles.coverage_head_container}>
          <div
            className={[styles.coverage_info_container, styles.coverage_bonus_title_container].join(
              ' '
            )}
          >
            <Text text={coverageTableTexts.bonus} customClass={bonusClasses} />
            <Tooltip
              isShow={showTooltip}
              onClose={onCloseTooltipHandler}
              position="top_right"
              icon={<Icon name="info" onClick={onOpenTooltipHandler} size="small" />}
              title={coverageTableTexts.tooltip.title}
              body={coverageTableTexts.tooltip.body}
            />
          </div>

          <div className={coverageContainerClass}>
            <Text text={coverageTableTexts.maximum} customClass={styles.coverage_text} />
          </div>
        </div>

        <div className={styles.coverage_items_container}>
          {category.items.map((item: CategoryItemsType, idx: number) => (
            <div key={`coverage_category_item_${idx}`} className={styles.coverage_item_container}>
              <div className={styles.coverage_body_description_container}>
                <Text text={item.description} customClass={styles.coverage_text} />
              </div>

              <div className={styles.coverage_info_container}>
                <Text text={`${item.bonus.value.percentage}%`} customClass={bonusValueClasses} />
              </div>

              <div className={styles.coverage_info_container}>
                <Text text={maximumValue(item)} customClass={styles.coverage_text} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {!!category.totalMaximum && (
        <div className={styles.coverage_total_maximum_container}>
          <Text text={coverageTableTexts.totalMaximum} customClass={styles.coverage_total_text} />
          <Text
            text={`${formatUF(category.totalMaximum.value.uf, { decimals: 0 })} ${
              category.totalMaximum.description
            }`}
            customClass={styles.coverage_total_text}
          />
        </div>
      )}
    </div>
  );
}

CoverageDetail.propTypes = {
  category: PropTypes.shape(detailsCategoriesPropTypes).isRequired,
};

function CoverageTable({ categories }: InferProps<typeof CoverageTable.propTypes>) {
  return (
    <div>
      {categories.map((category: DetailsCategoriesType, key: number) => (
        <div key={`coverage_category_${key}`} className={styles.coverage_category_container}>
          {category.default ? (
            <CoverageDetail category={category} />
          ) : (
            <Accordion title={category.title} outline>
              <CoverageDetail category={category} />
            </Accordion>
          )}
        </div>
      ))}
    </div>
  );
}

CoverageTable.propTypes = {
  categories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(detailsCategoriesPropTypes)),
    PropTypes.any,
  ]).isRequired,
};

export default CoverageTable;
