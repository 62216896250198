import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@BICE_Vida/components';

import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Row } from '@BICE_Vida/scp-components';
import {
  availablePlansModalText,
  benefitContentType,
  losHeroesBenefits,
  planBenefits,
} from '@/utils/generalTexts';
import { formatMoney, formatUF } from '@/utils/format';
import Accordion from '@/designSystem/Accordion';
import componentsId from '@/utils/componentsId';
import Text from '@/designSystem/Text';
import Tabs from '@/designSystem/Tabs';
import { ModalFull } from '@/designSystem/ModalFull';

import { AvailablePlan, ModalCoverage } from '@/src/types/availablePlans';
import { UF } from '@/src/types/uf';

import AvailablePlanCoverage from '../AvailablePlanCoverage';
import styles from './AvailablePlanModal.module.scss';
import { getBranding } from '@/redux/wizard/selectors';
import { Col } from '@/designSystem/Container/Container';

import PawerAssistance from './PawerAssistance/PawerAssistance';

type AvailablePlanModalT = {
  isOpen?: boolean;
  handleClose: () => void;
  plan: AvailablePlan;
  coverages: ModalCoverage;
  UF: UF;
  openPDF?: () => void;
  theme?: 'primary' | 'secondary';
  availablePlans?: any;
  isElite?: boolean;
  setPlanSelected?: (id: string, type: string, description: string) => void;
};

const AvailablePlanModal = ({
  isOpen = false,
  handleClose,
  plan,
  coverages,
  UF,
  openPDF,
  theme = 'primary',
  availablePlans,
  isElite = false,
  setPlanSelected,
}: AvailablePlanModalT) => {
  const asideClass = classNames(styles.aside_information, {
    [styles[`aside_information_theme_${theme}`]]: theme,
  });

  const modalFullClass = classNames(styles.aside_information, {
    [styles[`modal_full_custom_theme_${theme}`]]: theme,
  });

  const planCoveragesKeys = coverages
    ? Object.keys(coverages).filter((key) => key !== '__typename')
    : [];

  const [coverageSelected, setCoverageSelected] = useState<string>(planCoveragesKeys[0]);
  const [coveragePlanSelected, setCoveragePanSelected] = useState<string>(
    !!availablePlans ? availablePlans[0]._id : ''
  );

  const coveragesList: any = coverages;
  const showBmiEnforcement = !plan.coverage.dental;

  const router = useRouter();

  const coverageOptions = !!availablePlans
    ? availablePlans.map((plan: any) => ({
        id: plan._id,
        text: plan.description,
      }))
    : planCoveragesKeys
        .filter((key: string) => key !== '__typename' && !!coveragesList[key])
        .map((key: string) => {
          return {
            id: key,
            text: `${availablePlansModalText.insurance} ${
              (!!availablePlansModalText[key] && availablePlansModalText[key].coverage) ||
              coveragesList[key].description
            }`,
          };
        }) || [];

  const branding = useSelector(getBranding);

  let brokerTabs: { id: string; text: string }[];

  if (branding.code === 'losheroes')
    brokerTabs = [{ id: 'assistances_heroes', text: availablePlansModalText.assistances.title }];
  else if (plan.base === 'elite')
    brokerTabs = [{ id: 'assistances_pawer', text: availablePlansModalText.assistances.title }];
  else brokerTabs = [];

  const benefitsTab =
    !setPlanSelected && planBenefits.benefitsList.length
      ? [{ id: 'benefits', text: planBenefits.tabTitle }]
      : [];

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current && setPlanSelected) {
      const selectedPlan = availablePlans.filter((plan: any) => plan._id === coveragePlanSelected);
      setPlanSelected(selectedPlan[0]._id, selectedPlan[0].type, selectedPlan[0].description);
    } else {
      didMount.current = true;
    }
  }, [coveragePlanSelected]);

  const selectCoverage = (coverage: string) => {
    if (!!availablePlans) {
      setCoveragePanSelected(coverage);
    } else {
      setCoverageSelected(coverage);
    }
  };

  const handleButton = useCallback(() => {
    handleClose();
    router.push('/cotizacion/numero-trabajadores');
  }, []);

  const asideInformation = (
    <div className={asideClass}>
      <Text
        text={availablePlansModalText.coverageSection.title}
        customClass={styles.plans_details_coverage_title}
      />
      <Text text={plan.description} customClass={styles.plans_details_coverage_plan} />
      <span>
        <Text text={formatUF(plan.price.uf)} customClass={styles.plans_details_price} />
        <Text text="/" customClass={styles.plans_details_slash} />
        <Text
          text={formatMoney(plan.price.uf * UF.value)}
          customClass={styles.plans_details_price}
        />
      </span>
      <Text
        text={availablePlansModalText.coverageSection.description}
        customClass={styles.plans_details_coverage_description}
      />
      {router.pathname === '/' && (
        <div className={styles.button_container}>
          <Button
            text={availablePlansModalText.coverageSection.quotationButton}
            onClick={handleButton}
          />
        </div>
      )}
    </div>
  );

  const providers = Array.from(
    plan?.assistances
      ? plan.assistances.reduce((set, val) => set.add(val.provider), new Set<string>()).keys()
      : []
  );

  const tabsOptions = [...coverageOptions, ...benefitsTab, ...brokerTabs];

  useEffect(() => {
    if (isElite) setCoverageSelected('assistances_pawer');
  }, [isElite]);

  return (
    <ModalFull
      id={componentsId.plansDetailsModal}
      isOpen={isOpen}
      onClose={handleClose}
      asideChild={asideInformation}
      customClass={modalFullClass}
      theme={theme}
    >
      <div className={styles.plans_details_coverages_container}>
        {tabsOptions.length > 1 && (
          <Tabs
            tabs={[...tabsOptions]}
            active={!!availablePlans ? coveragePlanSelected : coverageSelected}
            onChange={selectCoverage}
          />
        )}
      </div>

      {!!coveragesList[coverageSelected] && (
        <div className={styles.plans_details_information_container}>
          <AvailablePlanCoverage
            details={coveragesList[coverageSelected].details}
            showBmiEnforcement={showBmiEnforcement}
          />
        </div>
      )}

      {!!openPDF && (
        <div className={styles.plans_details_information_btn_container}>
          <Button
            variant="text"
            size="medium"
            text={availablePlansModalText.btnPDF}
            onClick={openPDF}
          />
        </div>
      )}
      {coverageSelected === 'assistances' && (
        <div className={styles.plans_details_information_container}>
          <Row justify="center">
            <Col sm={12} md={10} lg={7}>
              <div>
                <Text
                  customClass={styles.plan_assistances_title}
                  text={availablePlansModalText.assistances.subtitle}
                />
              </div>
              <div>
                <Text
                  customClass={styles.plan_assistances_subtitle}
                  text={availablePlansModalText.assistances.providerText(providers)}
                />
              </div>
              <div className={styles.plan_assistances}>
                {plan.assistances?.map((ass) => (
                  <Accordion
                    title={ass.innerTitle}
                    outline
                    key={ass.title}
                    titleContainerClass={styles.plan_assistance}
                  >
                    <div className={styles.plan_assistance_description}>{ass.description}</div>
                  </Accordion>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {coverageSelected === 'benefits' && (
        <div className={styles.plans_details_information_container}>
          <Row justify="center">
            <Col sm={12} md={10} lg={7}>
              <div>
                <Text customClass={styles.plan_assistances_title} text={planBenefits.title} />
              </div>
              <div>
                <Text customClass={styles.plan_assistances_subtitle} text={planBenefits.subtitle} />
              </div>
              <div className={styles.plan_benefits}>
                {planBenefits.benefitsList.map((benefit) => (
                  <Accordion
                    title={benefit.title}
                    outline
                    key={benefit.title}
                    titleContainerClass={styles.plan_assistance}
                  >
                    <div className={styles.plan_assistance_description}>
                      {benefit.content.map((content, index) => {
                        switch (content.type) {
                          case benefitContentType.description:
                            return (
                              <Text
                                key={index}
                                customClass={styles.benefit_paragraph}
                                text={content.content}
                              />
                            );
                          case benefitContentType.list:
                            return (
                              <ul key={index}>
                                {(content.content as Array<string>).map((item) => (
                                  <li key={item}>{item}</li>
                                ))}
                              </ul>
                            );
                          default:
                            return null;
                        }
                      })}
                    </div>
                  </Accordion>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {coverageSelected === 'assistances_heroes' && (
        <div className={styles.plans_details_information_container}>
          <Row justify="center">
            <Col sm={12} md={10} lg={10}>
              <div>
                <Text customClass={styles.plan_assistances_title} text={losHeroesBenefits.title} />
              </div>
              <div>
                <Text
                  customClass={styles.plan_assistances_subtitle}
                  text={losHeroesBenefits.subTitle}
                />
              </div>
              <div className={styles.plan_assistances}>
                <Row className={styles.benefit_row}>
                  {losHeroesBenefits.cards.map((benefit) => (
                    <Col key={benefit.description}>
                      <Row className={styles.benefit_card}>
                        <Col sm={4} className={styles.benefit_col}>
                          <img src={benefit.image} alt="" className={styles.benefit_image} />
                        </Col>
                        <Col sm={8}>
                          <Row>
                            <Col className={styles.benefit_discount}>{benefit.discount}</Col>
                          </Row>
                          <Row>
                            <Col className={styles.benefit_description}>{benefit.description}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                <div className={styles.benefit_alert}>
                  <div className={styles.benefit_img}>
                    <img alt="" src={losHeroesBenefits.cat.image} />
                  </div>
                  <div>
                    <div>{losHeroesBenefits.cat.text}</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {coverageSelected === 'assistances_pawer' && <PawerAssistance />}
    </ModalFull>
  );
};

export default AvailablePlanModal;
