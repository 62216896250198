import PropTypes from 'prop-types';

export const detailsCategoriesPropTypes = {
  title: PropTypes.string.isRequired,
  default: PropTypes.bool.isRequired,
  totalMaximum: PropTypes.shape({
    description: PropTypes.string.isRequired,
    value: PropTypes.shape({
      uf: PropTypes.number.isRequired,
    }).isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      bonus: PropTypes.shape({
        value: PropTypes.shape({
          percentage: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
      maximum: PropTypes.shape({
        includeValue: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.shape({
          uf: PropTypes.number.isRequired,
        }),
      }).isRequired,
    }).isRequired
  ).isRequired,
};

export const detailsPropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  categories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(detailsCategoriesPropTypes)),
    PropTypes.any,
  ]).isRequired,
};
