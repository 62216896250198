import React, { useEffect, useState, Fragment } from 'react';

import { Row, Col } from '../../designSystem/Container';
import Text from '../../designSystem/Text';
import Alert from '../../designSystem/Alert';
import Chip from '../../designSystem/Chip';

import BmiCoverage from './BmiCoverage';
import CoverageTable from '../CoverageTable';

import styles from './AvailablePlanCoverage.module.scss';

import { internationalCoverageText, coveragesTabs } from '../../utils/generalTexts';

import { CoverageDetails } from '../../types/availablePlans';

type AvailablePlanCoverageProps = {
  details: CoverageDetails;
  showBmiEnforcement: boolean;
};

function AvailablePlanCoverage({ details, showBmiEnforcement }: AvailablePlanCoverageProps) {
  const [categorySelected, setCategorySelected] = useState<string>('');

  useEffect(() => {
    setCategorySelected(coveragesTabs.coverages);
  }, []);

  return (
    <Fragment>
      <Row justify="center">
        <Col sm={12} md={10} lg={7}>
          <Text text={details.title} customClass={styles.coverage_title} />
        </Col>
      </Row>

      <Row justify="center">
        <Col sm={10} md={9} lg={7}>
          <Text text={details.description} customClass={styles.coverage_description} />
        </Col>
      </Row>

      {!!details.categories.length && (
        <Row justify="center">
          <Col sm={12} md={10} lg={7} className={styles.plans_details_details_col}>
            {showBmiEnforcement ? (
              <Chip
                categories={Object.values(coveragesTabs)}
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
                customClass={styles.chip_container}
              />
            ) : (
              <Text customClass={styles.coverages_tab_text} text={coveragesTabs.coverages} />
            )}

            {categorySelected === coveragesTabs.coverages ? (
              <Fragment>
                <div className={styles.coverage_table_container}>
                  <CoverageTable categories={details.categories} />
                </div>
                <div className={styles.alert_container}>
                  <Alert
                    title={internationalCoverageText.title}
                    text={internationalCoverageText.text}
                    customClass={styles.coverage_alert}
                  />
                </div>
              </Fragment>
            ) : (
              <BmiCoverage />
            )}
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

export default AvailablePlanCoverage;
