type BaseDataLayerT = { [K: string]: unknown };

export const dataLayerEventParamsTemplate = {
  page: '',
  page_title: '',
  product: 'Mi PYME Segura',
  action_type: '',
  action: '',
  view_type: 'web',
  numero_trabajadores: '',
  product_type: '',
  cobertura_dental: '',
  cobertura_catastrofica: '',
  proteger_carga: '',
  valor: '',
  attribution: '',
};

export const dataLayerUserTemplate = {
  userId: '',
  useremail: '',
};

export type EventParamsT = Partial<typeof dataLayerEventParamsTemplate>;
export type UserParamsT = Partial<typeof dataLayerUserTemplate>;

export const trackEvent = ({
  eventParams,
  user,
}: {
  eventParams?: EventParamsT;
  user?: UserParamsT;
}) =>
  pushDataLayer({
    eventParams: {
      ...dataLayerEventParamsTemplate,
      ...eventParams,
    },
    user: {
      ...dataLayerUserTemplate,
      ...user,
    },
  });

export const pushDataLayer = ({ eventParams, user }: BaseDataLayerT): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'vpv',
    event_params: eventParams,
    user,
  });
};
