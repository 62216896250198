import React from 'react';
import classnames from 'classnames';

import Text from '../Text';

import styles from './Chip.module.scss';

type ChipT = {
  categories: string[];
  categorySelected: string;
  setCategorySelected: (category: string) => void;
  customClass?: string;
};

function Chip({ categories, categorySelected, setCategorySelected, customClass }: ChipT) {
  const onSelectCategoryHandler = (category: string) => setCategorySelected(category);

  const chipClass = classnames(styles.chip_categories, {
    [`${customClass}`]: customClass,
  });

  return (
    <div className={chipClass}>
      {categories.map((category: string, key: number) => (
        <div
          key={`chips_category_${key}`}
          className={styles.chip_category}
          onClick={() => onSelectCategoryHandler(category)}
        >
          <Text
            text={category}
            customClass={`${styles.chip_category_text} ${
              category === categorySelected ? styles.active : ''
            }`}
          />
        </div>
      ))}
    </div>
  );
}

export default Chip;
