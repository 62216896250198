import { Text } from '@BICE_Vida/components';
import { Col, Row } from '@BICE_Vida/scp-components';
import React from 'react';

import styles from './ConsultingAndDiscounts.module.scss';

type ConsultingAndDiscounts = {
  title: string;
  image: string;
  entries: {
    type: 'LIST' | 'PARAGRAPH';
    title?: string;
    list: React.ReactNode[];
  }[];
};

const CatMessage = ({ entries, title, image }: ConsultingAndDiscounts) => {
  return (
    <div className={styles.benefit_alert}>
      <div className={styles.benefit_img}>
        <img width={64} height={52} alt="" src={image} />
      </div>
      <Row justify="start">
        <Col sm={12} md={12} lg={12}>
          <div className={styles.column_container}>
            <Text className={styles.consulting_and_discounts_title}>{title}</Text>
            {entries.map((entry, index) => (
              <div key={`item_key_${index}`}>
                {title && (
                  <Text className={styles.consulting_and_discounts_subtitle}>{entry.title}</Text>
                )}
                {entry.type === 'LIST' ? (
                  <ul>
                    {entry.list.map((item, key) => (
                      <li key={key}>{item}</li>
                    ))}
                  </ul>
                ) : entry.type === 'PARAGRAPH' ? (
                  entry.list.map((item, key) => <p key={key}>{item}</p>)
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CatMessage;
