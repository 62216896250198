import React, { useEffect, useState, Fragment } from 'react';

import Text from '../../designSystem/Text';
import Icon from '../../designSystem/Icon';

import styles from './AvailablePlanCoverage.module.scss';

import { bmiCoverageText } from '../../utils/generalTexts';
import { breakPointMd } from '../../utils/constants';

function BmiCoverage() {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(window.innerWidth < breakPointMd);
  }, []);

  return (
    <Fragment>
      <Text text={bmiCoverageText.title} customClass={styles.coverage_title} />
      <Text text={bmiCoverageText.description} customClass={styles.coverage_description} />
      {Object.values(bmiCoverageText.cards).map((card: any, key: number) => (
        <div className={styles.card_container} key={`card_bmi_${key}`}>
          <div className={styles.card_content}>
            <Text text={card.title} customClass={styles.card_description} />
            <Icon
              name={isMobile ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
              size="medium"
              customClass={styles.icon}
            />
            <Text text={card.subtitle} customClass={styles.card_description} />
          </div>
        </div>
      ))}
      <Text text={bmiCoverageText.alert} customClass={styles.coverage_bmi_alert} />
    </Fragment>
  );
}

export default BmiCoverage;
