import React, { useCallback, useMemo, useState } from 'react';
import { Row } from '@BICE_Vida/scp-components';
import Image from 'next/image';
import { Col } from '@/designSystem/Container/Container';
import styles from './PawerAssistance.module.scss';
import { assistanceTableTexts, planElitePawerAssistance } from '@/utils/generalTexts';
import Accordion from '@/designSystem/Accordion';
import AssistanceTable from '@/components/AssistanceTable';
import CatMessage from '@/components/ConsultingAndDiscounts';

const PawerAssistance = () => {
  const entries = useMemo(() => {
    return Object.entries(planElitePawerAssistance.categories);
  }, []);

  const [activeChip, setActiveChip] = useState(entries[0][0]);

  const handleChipClick = useCallback(function handleChipClick(
    event: React.PointerEvent<HTMLButtonElement>
  ) {
    setActiveChip(event.currentTarget.value);
  },
  []);

  const coverages = (
    <Accordion
      title={planElitePawerAssistance.title}
      outline
      stayOpen
      key={planElitePawerAssistance.title}
      titleContainerClass={styles.plan_assistance}
    >
      <Row justify="start">
        <Col size={12}>
          <AssistanceTable
            title={planElitePawerAssistance.categories.coverages.table.title}
            content={planElitePawerAssistance.categories.coverages.table.content}
          />
          <CatMessage
            entries={planElitePawerAssistance.categories.coverages.catCard.lists}
            image={assistanceTableTexts.consultingAndDiscounts.image}
            title={assistanceTableTexts.consultingAndDiscounts.title}
          />
        </Col>
      </Row>
    </Accordion>
  );

  const howTo = (
    <Row className={styles.howto}>
      <Col size={12} className={styles.title}>
        {planElitePawerAssistance.categories.howTo.information.title}
      </Col>
      <Col size={12} className={styles.paragraph}>
        {planElitePawerAssistance.categories.howTo.information.paragraphs.map((p, idx) => (
          <>
            <p key={idx}>{p}</p>
            {idx < planElitePawerAssistance.categories.howTo.information.paragraphs.length - 1 && (
              <br />
            )}
          </>
        ))}
      </Col>
      <Col size={12}>
        <CatMessage
          entries={planElitePawerAssistance.categories.howTo.catCard.list}
          image={assistanceTableTexts.consultingAndDiscounts.image}
          title={planElitePawerAssistance.categories.howTo.catCard.title}
        />
      </Col>
    </Row>
  );

  const reinbursement = (
    <Row className={styles.howto}>
      <Col size={12} className={styles.title}>
        {planElitePawerAssistance.categories.reinbursement.information.title}
      </Col>
      <Col size={12} className={styles.paragraph}>
        {planElitePawerAssistance.categories.reinbursement.information.paragraphs.map((p, idx) => (
          <p key={idx}>{p}</p>
        ))}
      </Col>
      <Col size={12} className={styles.image_container}>
        {planElitePawerAssistance.categories.reinbursement.links.map((l, idx) => (
          <a key={idx} href={l.link} target="_blank" rel="noreferrer">
            <Image src={l.src} alt={l.alt} width={133} height={38} />
          </a>
        ))}
      </Col>
    </Row>
  );

  const telemedicine = (
    <Row className={styles.howto}>
      <Col size={12} className={styles.title}>
        {planElitePawerAssistance.categories.telemedicine.information.title}
      </Col>
      <Col size={12} className={styles.paragraph}>
        {planElitePawerAssistance.categories.telemedicine.information.paragraphs.map((p, idx) => (
          <p key={idx}>{p}</p>
        ))}
      </Col>
    </Row>
  );

  return (
    <Row justify="center" className={styles.pawer_container}>
      <Col size={12} md={10} lg={7}>
        <div className={styles.plan_benefits}>
          <p className={styles.assistance_description}>{planElitePawerAssistance.description}</p>
        </div>
      </Col>
      <Col size={12} md={10} lg={7} className={styles.chip_box}>
        {entries.map(([k, v], idx) => (
          <button
            key={idx}
            className={styles.chip}
            value={k}
            onClick={handleChipClick}
            title={k === activeChip ? 'selected' : ''}
          >
            {v.pill}
          </button>
        ))}
      </Col>
      <Col size={12} md={10} lg={7}>
        {activeChip === 'coverages'
          ? coverages
          : activeChip === 'howTo'
          ? howTo
          : activeChip === 'reinbursement'
          ? reinbursement
          : activeChip === 'telemedicine'
          ? telemedicine
          : ''}
      </Col>
    </Row>
  );
};

export default PawerAssistance;
