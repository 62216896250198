import React from 'react';
import classnames from 'classnames';

import { Text } from '../../designSystem';

import styles from './AssistanceTable.module.scss';

import { formatMoney } from '../../utils/format';
import { assistanceTableTexts } from '../../utils/generalTexts';
import { AssistanceDetailsCategories } from '@/src/types/assistance';

const CoverageTable = ({ title, content }: AssistanceDetailsCategories) => {
  return (
    <div>
      <div className={styles.assistance_category_container}>
        <div className={styles.assistance_container}>
          <div className={styles.assistance_table}>
            <table className={styles.assistance_items_container}>
              <tbody>
                {content.map((row, idx: number) => (
                  <tr key={idx} className={styles.assistance_item_container}>
                    {row.map((col, id2) => (
                      <td key={id2}>{col}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverageTable;
