import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import styles from './Tabs.module.scss';

function Tabs({ id, tabs, active, onChange }: InferProps<typeof Tabs.propTypes>) {
  const onChangeHandler = (tabSelected: any) => {
    onChange(tabSelected);
  };

  return (
    <div id={id} className={styles.tabs_options}>
      <div className={styles.tabs_options_container}>
        {tabs.map((tab: any) => (
          <div
            id={tab.id}
            key={`tabs_tab_${tab.id}`}
            className={`${styles.tabs_option} ${
              tab.id === active ? styles.tabs_option_active : ''
            }`}
            onClick={() => onChangeHandler(tab.id)}
          >
            <p className={styles.tabs_option_text}>{tab.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  id: '',
};

export default Tabs;
